import { marked } from 'marked'
import data from './index.md'

let result = null

function getRealtimeInfo () {
  if (result) return result
  result = []
  const html = marked(data)
  const dom = document.createRange().createContextualFragment(html).children

  let current = []
  for (let i = 0; i < dom.length; i++) {
    const item = dom[i]
    const tagName = item.tagName.toLocaleLowerCase()
    if (tagName === 'h3') continue
    if (tagName === 'p') {
      current.push(item.innerHTML)
    } else if (tagName === 'hr') {
      result.push(current)
      current = []
    }
  }
  result.push(current)

  result = result.map(item => {
    const [content, imgStr] = item
    const [date, title, linkStr] = content.split('\t')

    const imgReg = /^<img src="((.)*)" alt="(.)*">$/
    const imgMatches = imgStr.match(imgReg)
    const img = imgMatches[1]

    const linkReg = /^<a href="((.)*)">((.)*)<\/a>$/
    const linkMatches = linkStr.match(linkReg)
    const link = linkMatches[1]

    return {
      date,
      title,
      link,
      img
    }
  })

  return result
}

export function getHomePageRealtimeInfo () {
  const result = getRealtimeInfo()
  return result.slice(0, 3)
}

export default getRealtimeInfo
